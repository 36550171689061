import React from "react";
// import { Link } from "gatsby";
// import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import ContantSlideOutModal from '../component/ContactSlideout/index';
const ContactModal = () => {
  return (
    // <ModalRoutingContext.Consumer>
    //   {({ modal, closeTo }) => (
    //     <div>
    //       {modal ? (
    //         <Link to={closeTo}>Close</Link>
    //       ) : (
    //       <HomeValuation />
    //       )}
    //     </div>
    //   )}
    // </ModalRoutingContext.Consumer>
    <ContantSlideOutModal />
  );
};

export default ContactModal;
